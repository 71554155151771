import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tabs, Tab } from 'hammerhead';
import {
  EDGE_POPS,
  BCON_URL,
  HC_URL,
  ST_URL,
  QT_URL,
  EC_HEADERS
} from './Config';
const axios = require('axios');

class App extends Component {
  state = {
    results: {},
    value: '',
    copied: false
  };

  checkPops = (pop, ts) => {
    return axios
      .get(HC_URL(pop, ts))
      .then((response) => response.data)
      .catch((err) => console.log(err.message));
  };

  checkSpeed = (ts) => {
    return axios.get(ST_URL(ts), EC_HEADERS);
  };

  checkQuicSpeed = (ts) => {
    return axios.get(QT_URL(ts));
  };

  fetchInfo = async () => {
    const ts = Date.now();
    let list = [];

    EDGE_POPS.map((pop) => list.push(this.checkPops(pop, ts)));

    Promise.all([this.checkSpeed(ts), this.checkQuicSpeed(ts), list])
      .then((values) => {
        this.setState((prevState) => ({
          results: {
            ...{
              headers: values[0].headers
            },
            ...prevState.results
          }
        }));
        return values;
      })
      .then((values) => {
        this.getPOPTiming();
        this.getSpdTiming(values);
      })
      .then(() => {
        this.setState({ value: btoa(JSON.stringify(this.state.results)) });
      });
  };

  sendBcon = () => {
    const random_num = Math.floor(Math.random() * 100000);
    axios.get(BCON_URL(random_num));
  };

  getPOPTiming = () => {
    if (performance) {
      const rs = performance.getEntriesByType('resource');
      const entries = rs.filter((item) => item.name.includes('/client'));

      const poplist = entries
        .map((entry) => {
          const strs = entry.name.split(/\/\/(.*?)1/g);
          entry.id = strs[1];
          return entry;
        })
        .sort((a, b) => {
          return parseInt(this.getDuration(a)) - parseInt(this.getDuration(b));
        });

      this.setState((prevState) => ({
        results: {
          ...{
            'pop-latency': poplist
          },
          ...prevState.results
        }
      }));
    }
  };

  getSpdTiming = (values) => {
    if (performance) {
      const bw = {};
      const rs = performance.getEntriesByType('resource');
      const results = rs.filter((item) => item.name.includes('/speedtest'));

      results.map((obj, idx) => {
        const httpver = values[idx].headers['x-httpver'];
        const serverh = values[idx].headers.server;
        const pop = serverh && serverh.split(/\((.*)\//g)[1].toUpperCase();
        const name = obj.name.substring(0, obj.name.lastIndexOf('?'));

        if (obj.name.includes('quic')) {
          bw.quic = {
            name: name,
            protocol: httpver,
            anycast: pop,
            throughput: obj

          };
        } else {
          bw.http = {
            name: name,
            protocol: httpver,
            anycast: pop,
            throughput: obj
          };
        }
        return bw;
      });

      this.setState((prevState) => ({
        results: {
          ...bw,
          ...prevState.results
        }
      }));
    }
  };

  getDuration = (obj) => {
    if (typeof obj === 'object') {
      return (obj.responseEnd - obj.requestStart).toFixed(0) + ' ms';
    } else {
      return obj;
    }
  };

  getPrintable = (obj) => {
    if (typeof obj === 'object') {
      // this is either speedtest.bw[quic] or speedtest.bw[http] timing object
      // calculate Mbps is Megabits divide by seconds
      return (
        (
          obj.transferSize /
          2 ** 17 /
          ((obj.responseEnd - obj.requestStart) / 1000)
        ).toFixed(0) + ' Mbps'
      );
    } else {
      // this is a string value (either a client header or the pop for the speed test)
      return obj;
    }
  };

  onCopy = () => {
    this.setState({ copied: true });
    this.setState({ activeTab: 2 });
  };

  selectText = (e) => {
    window.getSelection().selectAllChildren(e.target);
  };

  componentDidMount() {
    this.fetchInfo();
    this.interval = setInterval(() => {
      this.sendBcon();
    }, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const fsty2 = { fontSize: '2vh' };
    const fsty1 = { fontSize: '1.5vh', whiteSpace: 'normal' };
    const twidth = { width: '25vw' };

    return (
      <div className="container p-5">
        <div className="main-content">
          <div className="row-underlined">
            <h1>CDN Client Info</h1>
          </div>
          <div className="row">
            <p className="col">
              Submit the information contained on this page by pasting the JSON
              or Base64 text along with your ticket at <a href="https://edgecast.service-now.com"> 
              edgecast.service-now.com</a>
            </p>
          </div>
          <div className="row mb-5">
            <div className="col">
              {this.state.copied ? (
                <button className="btn btn-primary" type="button"  data-test-hook="btn-copied">
                  Copied to clipboard
                </button>
              ) : (
                <CopyToClipboard onCopy={this.onCopy} text={this.state.value}>
                  <button className="btn btn-secondary" type="button" data-test-hook="btn-copy">
                    Copy Base64
                  </button>
                </CopyToClipboard>
              )}
            </div>
          </div>

          <Tabs
            activeTab={this.state.activeTab}
            onTabSelect={(tab) => this.setState({ activeTab: tab })}>
            <Tab label="HTML">
              <div className="mt-4">
                <table className="table table-striped"  data-test-hook="table-pops">
                  <colgroup>
                    <col style={twidth}></col>
                    <col></col>
                  </colgroup>
                  <tbody>
                    {Object.keys(this.state.results).map((key, idx) => {
                      return (
                        <React.Fragment key={key}>
                          <tr className="bg-accent">
                            <th
                              colSpan="2"
                              className="text-capitalize lead text-white"
                              style={fsty2}>
                              {key}
                            </th>
                          </tr>
                          {Array.isArray(this.state.results[key])
                            ? this.state.results[key].map((pop) => (
                              <tr key={pop.id}>
                                <th
                                  scope="row"
                                  className="text-right text-uppercase"
                                  style={fsty1}>
                                  {pop.id} :
                                </th>
                                <td style={fsty1}>{this.getDuration(pop)}</td>
                              </tr>
                            ))
                            : Object.keys(this.state.results[key]).map((k, idx) => (
                              <tr key={k}>
                                <th
                                  scope="row"
                                  className="text-right text-uppercase"
                                  style={fsty1}>
                                  {k} :
                                </th>
                                <td style={fsty1}>
                                  {this.getPrintable(
                                    this.state.results[key][k]
                                  )}
                                </td>
                              </tr>
                            )
                            )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Tab>
            <Tab label="JSON">
              <div className="mt-4">
                <pre
                  className="lead"
                  style={{ fontSize: '1.5vh' }}
                  onDoubleClick={this.selectText}>
                  {JSON.stringify(this.state.results, null, 4)}
                </pre>
              </div>
            </Tab>
            <Tab label="Base64">
              <div className="mt-4">
                <code
                  className="lead d-block p-3"
                  onDoubleClick={this.selectText}>
                  {this.state.value}
                </code>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default App;