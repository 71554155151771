export const BCON_URL = (random_num) => {
  return (
    '//ec-debug-page-' +
    random_num +
    '.ac.bcon.ecdns.net/udp/' +
    random_num +
    '.png'
  );
};

export const HC_URL = (pop, time_stamp) => {
  return (
    '//' +
    pop +
    '1.wpc.edgecastcdn.net/000002/client/health_check.html?v=' +
    time_stamp
  );
};

export const ST_URL = (time_stamp) => {
  return './speedtest?v=' + time_stamp;
};

export const QT_URL = (time_stamp) => {
  return '//tools-quic.vdms.com:443/speedtest?v=' + time_stamp;
};

export const EC_HEADERS = {
  headers: {
    'X-EC-Debug': 'x-ec-cache,x-ec-check-cacheable,x-ec-cache-state'
  }
};

// export const EDGE_POPS = ['AGA', 'AGB', 'AGC', 'AKL', 'AMA', 'AMB', 'AMS', 'ATL', 'BKK', 'BLR', 'BOG', 'BSA', 'CCU', 'CHA', 'CHB', 'CMC', 'DAA', 'DAB', 'DCB', 'DCC', 'DCD', 'DNA', 'DTW', 'FCN', 'FRB', 'FRC', 'HHP', 'HKC', 'HND', 'JFK', 'JNB', 'LAA', 'LAB', 'LAX', 'LCY', 'LHA', 'LHB', 'LHC', 'LPL', 'MDR', 'MDW', 'MEB', 'MIB', 'MIC', 'MIL', 'MNL', 'MRS', 'NDL', 'NRT', 'NYA', 'NYB', 'OXR', 'PAA', 'PAB', 'PHD', 'PHX', 'RIB', 'SAA', 'SAB', 'SEB', 'SEC', 'SGB', 'SGC', 'SGT', 'SKA', 'SPB', 'SYA', 'TIR', 'TKA', 'TKB', 'TPE', 'VIA', 'WAW'];
// export const EDGE_POPS = ['AGA','AGB','AKL','AMA','AMB','BLR','BSA','CHA','CHB','CMC','DAA','DAB','DCB','DCC','DCD','DNA','DTW','FRB','FRC','HKC','JNB','LAA','LAB','LHA','LHB','LHC','LPL','MDR','MDW','MEB','MIB','MIC','MIL','MRS','NDL','NYA','NYB','PAA','PAB','PHD','PHX','RIB','SAA','SAB','SEB','SEC','SGB','SGC','SKA','SPB','SYA','TIR','TKA','TKB','TPE','VIA','WAW' ];

export const EDGE_POPS = [
  'AGA',
  'AGC',
  'AKL',
  'AMA',
  'AMB',
  'AMC',
  'BAQ',
  'BDC',
  'BLR',
  'BOG',
  'BSA',
  'BSB',
  'BUE',
  'BKK',
  'CGK',
  'CHA',
  'CHB',
  'CHD',
  'CHE',
  'CMC',
  'CPH',
  'DAA',
  'DAB',
  'DAC',
  'DCB',
  'DCC',
  'DCD',
  'DCE',
  'DMK',
  'DNA',
  'DTW',
  'EZE',
  'FOR',
  'FRB',
  'FRC',
  'FRD',
  'GDL',
  'GMP',
  'HEL',
  'HKC',
  'HKT',
  'HOU',
  'JAU',
  'JNB',
  'KHH',
  'KWI',
  'LAA',
  'LAB',
  'LAC',
  'LHA',
  'LHB',
  'LHC',
  'LHD',
  'LIS',
  'LPL',
  'LSV',
  'MBW',
  'MDE',
  'MDR',
  'MDW',
  'MEX',
  'MIB',
  'MIC',
  'MID',
  'MIL',
  'MNL',
  'MRS',
  'MSP',
  'MTY',
  'MUC',
  'NAG',
  'NDL',
  'NWA',
  'NYA',
  'NYB',
  'NYD',
  'OMM',
  'OSA',
  'OTP',
  'PAA',
  'PAB',
  'PBC',
  'PHD',
  'PHX',
  'PIT',
  'PVR',
  'QRO',
  'RIB',
  'RIX',
  'SAA',
  'SAB',
  'SAC',
  'SCL',
  'SEB',
  'SEC',
  'SED',
  'SGB',
  'SGC',
  'SGD',
  'SKA',
  'SOF',
  'SPB',
  'STO',
  'SYA',
  'TKA',
  'TKB',
  'TKC',
  'TIR',
  'TPE',
  'UAE',
  'VAP',
  'VIA',
  'WMI',
  'WIL',
  'XIJ'
];
